import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="super-container d-flex align-items-center">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-4 text-white mb-4">
            <h1 className="display-1 mb-0">
              Amy
              <br />
              Smedira
              <br />
              Realty
            </h1>
          </div>
          <div className="col-sm-12 col-md-6 offset-md-2 contact-info">
            <h2>Your real estate agent is just a click away.</h2>
            <p className="fst-italic">Connect with Amy:</p>
            <p>
              P: <a href="tel:2164083681">216-408-3681</a>
            </p>
            <p>
              E: <a href="mailto:amy@amysmedira.com">amy@amysmedira.com</a>
            </p>
            <p>
              <a
                href="https://amysmedira.exprealty.com"
                target="_blank"
                rel="noreferrer"
              >
                View Listings
              </a>
            </p>
            <p>
              <a
                href="https://www.redfin.com/real-estate-agents/amy-smedira/reviews"
                target="_blank"
                rel="noreferrer"
              >
                Agent Reviews
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
